@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.about {
    text-align: center;

    &__section {
        margin: 150px 7%;
        width: auto;

        @include mobile {
            margin: 100px 10%;
        }
    }

    &__img {
        margin: 60px 0 0;
        max-width: 400px;
        border-radius: 50%;
        background: rgba(119, 192, 50, 0.7);;

        @include mobile {
            max-width: 200px;
        }
    }
}