@use './variables' as *;

@mixin reset-list {
    list-style-type: none;
        padding: 0;
        margin: 0;
}

@mixin reset-link {
    color: $black;
    text-decoration: none;
}

// media queries

@mixin mobile {
    @media (max-width: 414px) {
        @content;
    }
}

@mixin mobile-large {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: 1444px) {
        @content;
    }
}

@mixin desktop-large {
    @media (min-width: 1444px) {
        @content;
    }
}