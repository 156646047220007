@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.contact {
    text-align: center;

    &__section {
        margin: 150px 7%;
        width: auto;

        @include mobile {
            margin: 100px 10%;
        }
    }

    &__inner-section {
        margin: 4em 0;

        @include mobile {
            margin: 2em 0;
        }
        
        h3, h3 span {
            margin-bottom: 0;
        }

        h3 ~ p {
            margin-top: 0;
        }
    }
}

h3.primary {
    color: $primary;
}

h3.secondary {
    color: $secondary;
}

p a {
    @include reset-link;
}