@use './styles/typography' as *;
@use './styles/mixins' as *;
@use './styles/variables' as *;

body {
    @include mont;
}

h1, h2, h3 {
    @include libre;

    span {
        display: block;
        font-size: 0.6em;
        text-transform: uppercase;
        @include mont;
        font-weight: normal;
        margin-bottom: 1em;
    }
}

p {
    line-height: 1.7;;
}

.button {
    @include mont;
    @include reset-link;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px 40px;
    min-width: 200px;
    // border: 1px solid black;
    border-radius: 5px;
    margin: 10px;
    color: white;
    background-color: $primary;

    @include mobile {
        margin: 10px 0;
    }

    &.primary {
        background-color: $primary;
    }

    &.secondary {
        background-color: $secondary;
    }
}

.photo-divider {
    $height: 400px;

    @include mobile {
        $height: 150px;
    }

    @include mobile-large {
        $height: 300px;
    }

    height: $height;
    overflow: hidden;
    position: relative;

    .fade {
        background: linear-gradient(0deg, rgba(255,255,255,1) 5%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 95%);
        width: 100%;
        height: $height;
        position: absolute;
        top: 0;
        left: 0;

    }

    img {
        width: 100%;
        position: relative;
    }
}