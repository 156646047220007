@use './variables' as *;

@font-face {
    font-family: 'Allura';
    src: url('../assets/fonts/Allura-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('../assets/fonts/LibreBaskerville-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('../assets/fonts/LibreBaskerville-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('../assets/fonts/LibreBaskerville-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}

@mixin mont {
    font-family: Montserrat, Helvetica sans-serif;
}

@mixin libre {
    font-family: 'Libre Baskerville', serif;
}

@mixin allura {
    font-family: 'Allura';
}

span.emphasis {
    font-weight: bold;

    &.primary {
        color: $primary;
    }

    &.secondary {
        color: $secondary;
    }
}

/*
logo - allura

body copy - monsterrat
emphasis - montserrat bold

cta - montserrat bold

headers - libre

colors 
blue rgb(160, 196, 203)
pink rgb(225, 195, 195)
black rgb(62, 62, 61)
*/