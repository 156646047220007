@use '../../styles/typography' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.home {
    text-align: center;

    &__section {
        margin: 150px 7%;
        width: auto;

        @include mobile {
            margin: 100px 10%;
        }
        
    }
}

.section {
    &-divider {
        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
    }
}

.list {
    text-align: left;
    padding: 0 40px;

    @include mobile {
        margin: 0;
    }

    &__container {
        display: flex;
        justify-content: center;

        @include mobile {
            flex-direction: column;
        }
    }
}
