//colors

//originals
// $blue: rgba(160, 196, 203);
$pink: rgb(225, 195, 195);
$black: rgb(62, 62, 61);

//test
$red: #A83853;
$orange: #D5605D;

$green: #568b25;
$darkgreen: #0f5009;

$filter-green: brightness(0) saturate(100%) invert(42%) sepia(84%) saturate(409%) hue-rotate(48deg) brightness(94%) contrast(84%);
$filter-darkgreen: brightness(0) saturate(100%) invert(16%) sepia(44%) saturate(3459%) hue-rotate(85deg) brightness(102%) contrast(93%);

$primary: $green;
$secondary: $darkgreen;