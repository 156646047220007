@use '../../styles/typography' as *;
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.logo {
    margin: 40px 0;

    img {
        max-width: 200px;
        filter: $filter-green;
        // filter: $filter-darkgreen;

        @include mobile {
            max-width: 200px;
        }
    }
    .allura {
        @include allura;
        font-size: 3em;
        font-weight: 500;
        line-height: 1;
        margin: 0;

        @include mobile {
            font-size: 2em;
        }
    }
    .mont {
        @include mont;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 1;
        margin: 0;

        @include mobile {
            font-size: 0.5em;
        }

        @include mobile-large {
            font-size: 0.7em;
        }
    }

    span {
        margin: 0 10px;
    }
}