@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.nav {
    text-align: center;

    &__logo {
        max-width: 400px;

        @include mobile {
            max-width: 200px;
        }
    } 
    &__list {
        @include reset-list;
        display: flex;
        justify-content: center;
        background-color: $secondary;

        @include mobile {
            
        }
    }
    &__list-item {
        padding: 10px;
        margin: 0 10px;

        @include mobile {
            margin: 0 5px;
        }
    }
    &__link {
        @include reset-link;
        text-decoration: none;
        padding: 0 10px;
        text-transform: uppercase;
        font-weight: bold;
        transition: 0.2s;
        border-bottom: 2px solid transparent;
        color: white;

        @include mobile {
            padding: 5px;
        }
        
        &--active, &:hover {
            border-bottom: 2px solid white;
        }
    }
}