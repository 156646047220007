@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.footer {
    background-color: $black;
    color: white;

    display: flex;
    justify-content: space-around;
    padding: 2rem 1rem;

    @include mobile-large {
        flex-direction: column;
    }

    &__section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1 1 0;

        @include mobile {
            margin: 10px 0;
        }
    }

    &__link:not(.button) {
        color: white;
        line-height: 2;

        // &.button {
        //     color: $black;
        //     line-height: 1.4;
        // }
    }
}